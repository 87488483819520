import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'setting-getaway-filter',
    data () {
        return {
            filter: {
                keywords    : ''
            }
        }
    },
    computed: {
        ...mapGetters({
            filterKeywords: 'settingGetaway/filterKeywords',
        })
    },
    created () {
        this.setFilters()
    },
    methods: {
        ...mapActions({
            fetchList: 'settingGetaway/GET_LIST',
        }),
        ...mapMutations({
            changeFilter    : 'settingGetaway/CHANGE_LIST_FILTER',
            setListLength   : 'settingGetaway/SET_LIST_LENGTH',
            changeSkip      : 'settingGetaway/CHANGE_SKIP'
        }),
        setFilters () {
            this.filter.keywords = this.filterKeywords
        },
        sendRequest: debounce(function () {
            this.fetchList()
        }, 500),
        changeFilterLocal (val, type) {
            this.changeFilter({
                type    : type,
                value   : val
            });
            
            this.sendRequest()
            
            // reset
            this.setListLength(0)
            this.changeSkip(0)
        }
    }
}
