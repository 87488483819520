import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'

export default {
    name    : 'setting-getaway-providers-general',
    mixin   : [mixins, validation],
    data () {
        return {
            valid       : false,
            search      : null,
            searchInput : null,
            providers   : [
                {
                    value : 'monobank',
                    label : 'mono'
                },
                {
                    value : 'fondy',
                    label : 'fondy'
                },
                {
                    value : 'stripe',
                    label : 'stripe'
                }
            ],
            payload     : {
                provider    : '',
                name        : '',
                publicKey   : '',
                secretKey   : ''
            }
        }
    },
    watch   : {
        currentItem () {
            if (this.currentItem && this.editId) {
                this.setData()
            }
        }
    },
    computed: {
        ...mapGetters({
            profile     : 'profile/profile',
            isLoading   : 'settingGetawayProviders/isLoading',
            currentItem : 'settingGetawayProviders/currentItem',
            settings    : 'templates/settings',
            list        : 'settingGetawayProviders/list',
            editId      : 'settingGetawayProviders/editId'
        })
    },
    created () {
        if (this.currentItem) {
            this.setData()
        }
        
        if (!this.settings) this.fetchSettings()
        
        this.$on('event-save', () =>{
            this.submit();
        });
    },
    methods: {
        ...mapActions({
            update          : 'settingGetawayProviders/UPDATE',
            create          : 'settingGetawayProviders/CREATE',
            fetchSettings   : 'templates/GET_SETTING',
        }),
        ...mapMutations({}),
        setData () {
            Object.assign(this.payload, this.currentItem)
        },
        createRequestPayload () {
            const formData = new FormData()
            
            for (const i in this.payload) {
                formData.append(snakeCase(i), this.payload[i] || '')
            }
            
            this.currentItem ? formData.append('_method', 'patch') : ''
            
            return formData
        },
        submit () {
            this.$refs.form.validate()
            
            if (this.valid) {
                if (this.currentItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'))
                        this.setData();
                    });
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'));
                    });
                }
            }
        }
    }
}
