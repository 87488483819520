import { mapActions, mapGetters, mapMutations } from 'vuex'
import dialogView from './dialog-view/index.vue'
import getawayFilter from '../../components/getaway-filter/index.vue'

export default {
    name        : 'setting-getaway-providers',
    data () {
        return {
            page            : 1,
            showRemoveDialog: false,
            removedItem     : null,
            list            : []
        }
    },
    components  : {
        dialogView,
        getawayFilter
    },
    computed    : {
        ...mapGetters({
            listProviders   : 'settingGetawayProviders/list',
            
            listData        : 'settingPaymentsData/list',
            
            limit           : 'settingGetawayProviders/limit',
            listLength      : 'settingGetawayProviders/listLength',
            isLoading       : 'settingGetawayProviders/isLoading',
            isListLoading   : 'settingGetawayProviders/isListLoading',
            openDialogView  : 'settingGetawayProviders/openDialogView'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'id'
                },
                {
                    text    : 'Provider',
                    value   : 'provider'
                },
                {
                    text    : 'Name',
                    value   : 'name'
                },
                {
                    text    : 'Public key',
                    value   : 'publicKey'
                },
                {
                    text    : 'Secret key',
                    value   : 'secretKey'
                },
                {
                    text    : 'Created at',
                    value   : 'createdAt'
                },
                {
                    text    : 'Cards',
                    value   : 'cards'
                },
                {
                    text    : 'Transactions',
                    value   : 'transactions'
                },
                {
                    text    : 'Actions',
                    sortable: false,
                    value   : 'actions'
                }
            ]
        }
    },
    created () {
        this.createList();
    },
    methods     : {
        ...mapActions({
            fetchList   : 'settingGetawayProviders/GET_LIST',
            fetchData   : 'settingPaymentsData/GET_LIST',
            remove      : 'settingGetawayProviders/DELETE'
        }),
        ...mapMutations({
            changeSkip      : 'settingGetawayProviders/CHANGE_SKIP',
            changeDialogView: 'settingGetawayProviders/CHANGE_DIALOG_VIEW',
            changeEditId    : 'settingGetawayProviders/CHANGE_EDIT_ID'
        }),
        async createList () {
            await this.fetchList();
            
            if(this.listProviders.length){
                var ids = [];
                
                for(let i = 0; i < this.listProviders.length; i++){
                    this.listProviders[i].cards          = 0;
                    this.listProviders[i].transactions   = 0;
                    
                    ids.push(this.listProviders[i].id);
                }
                
                this.list = this.listProviders;
                
                if(ids.length){
                    await this.fetchData({
                        payload : ids
                    });
                    
                    if(this.listData && this.listData.length){
                        for(var i = 0; i < this.listData.length; i++){
                            var p_id = this.listData[i].id;
                            
                            for(let p = 0; p < this.listProviders.length; p++){
                                if(this.listProviders[p].id == p_id){
                                    this.listProviders[p].cards          = this.listData[i].cards;
                                    this.listProviders[p].transactions   = this.listData[i].transactions;
                                    
                                    break;
                                }
                            }
                        }
                        
                        this.list = this.listProviders;
                    }
                }
            }
        },
        changePagination (index) {
            //this.changeSkip(this.limit * (index - 1))
            this.$vuetify.goTo(0);
            
            this.createList()
        },
        changeDialog (status = false, item = null) {
            this.changeEditId(item.id)
            this.changeDialogView(status)
        },
        changeDialogRemove (item) {
            this.showRemoveDialog = !!item
            this.removedItem = item
        },
        removeListItem () {
            this.remove(this.removedItem).then(() => {
                this.showRemoveDialog = false
                this.$toasted.success(this.$t('success_deleted'))
            })
        }
    }
}
